import AddressForm from "../Address.vue";
import { shallowMount } from "@/../tests/vueTestUtils.js";
import { createLocalVue } from "@vue/test-utils";
import "@testing-library/jest-dom";

let wrapper;

jest.mock("@/utils/mixins", () => ({
  getBusinessById: {
    computed: {
      business() {
        return {
          id: "business_id_1",
          country: "AE",
        };
      },
    },
  },
}));

const newAddress = {
  location: "Lake Terrace Tower, Cluster D",
  nickname: "Dogs JLT",
  lat: 25.0699855,
  lng: 55.1413319,
  area: "Jumeirah Lake Towers",
  city: "Dubai",
};

const existingAddress = {
  ...{ id: "336483b5-a371-490a-bfcf-dfe3db9a91ec" },
  ...newAddress,
};

const localVue = createLocalVue();

beforeEach(() => {
  wrapper = shallowMount(AddressForm, {
    propsData: {
      outletId: "outlet_id",
      businessId: "business_id",
      businessCode: "AE",
      address: newAddress,
    },
    mocks: {
      $apollo: {
        query: jest.fn(() => ({
          data: {
            outlet: {
              address: {},
              business: {},
            },
          },
        })),
        mutate: jest.fn(() => ({})),
      },
    },
    localVue,
  });
});

afterEach(() => {
  wrapper.destroy();
});

describe("Actions", () => {
  const triggerFormSubmit = async () => {
    const form = wrapper.find("form");
    await form.trigger("submit");
  };

  it("should call Apollo mutate when the form is submitted", async () => {
    expect(wrapper.find("[type=submit]").exists()).toBeTruthy();

    await triggerFormSubmit();
    expect(wrapper.vm.$apollo.mutate).toHaveBeenCalled();
  });

  it("should update address coordinates", async () => {
    const newCoordinates = {
      lat: 44.444,
      lng: 55.555,
    };
    await wrapper.find("googlemaps-stub").vm.$emit("changed", newCoordinates);

    expect(wrapper.vm.address.lat).toEqual(newCoordinates.lat);
    expect(wrapper.vm.address.lng).toEqual(newCoordinates.lng);
  });
});
